<template>
  <div class="my-trade">
    <VTradeProcedure
      :url-action-query="`${EXPAND_LOT}&search[MyLot]&search[Active]=active`"
      class-toggle-display="my-trade__toggle-display--admin"
    />
  </div>
</template>

<script>
import { ref } from 'vue'
import { goLotInfo } from '@/utils/goRoute'
import VTradeProcedure from '@/components/views/TradeProcedure/VTradeProcedure'
import { EXPAND_LOT } from '@/utils/consts'

export default {
  setup () {
    const display = ref('blocks')

    const setDisplay = value => {
      display.value = value
    }

    return {
      EXPAND_LOT,
      display,
      setDisplay,
      goLotInfo
    }
  },
  components: {
    VTradeProcedure
  }
}
</script>

<style lang="scss" scoped>
.my-trade {
  position: relative;

  &__toggle-display {
    position: absolute;
    top: -80px;
    right: 0;
  }
}
</style>
